/*
* BASE DEPENDENCIES
*/ /*****************************************
* Blackwell - MIXINS
*****************************************/
/*****************************************
* blackwell - FUNCTIONS
*****************************************/
/*==============================
	FONTS
==============================*/
/*==============================
	COLORS
==============================*/
/*==============================
	SIZES
==============================*/
/* COMPONENT VARIABLES */
/*==============================
	FORMS
==============================*/
/*==============================
	BUTTONS
==============================*/
/*
@keyframes fade-into-foreground {
	from {
		transform: scale(1);
		opacity: 1;
	} to {
		transform: scale(1.2);
		opacity: 0;
	}
}

@keyframes fade-into-background {
	from {
		transform: scale(1);
		opacity: 1;
	} to {
		transform: scale(.75);
		opacity: 0;
	}
}

@keyframes pop-into-foreground {
	from {
		transform: scale(.5);
		opacity: 0;
	} to {
		transform: scale(1);
		opacity: 1;
	}
}

@keyframes pop-into-foreground-with-bounce {
	0% {
		transform: scale(.5);
		opacity: 0;
	} 72% {
		transform: scale(1.07);
		opacity: .72;
	} 100% {
		transform: scale(1);
		opacity: 1;
	}
}

@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.5);
	} 100% {
		box-shadow: 0 0 0 1.5em rgba(0, 0, 0, 0);
	}
}

@keyframes slide-from-top {
	from {
		transform: translateY(-100%);
	} to {
		transform: translateY(0);
	}
}
*/
/*==============================
	INPUT, TEXTAREA, SELECT
==============================*/
/*==============================
	CHECKBOX, SWITCH, RADIO
==============================*/
/*
* COMMON BLOCKS
*/
.footer {
  text-align: center;
  margin-top: auto;
  padding-top: 2em;
  padding-bottom: 2em;
  position: relative;
  background-color: #062b29;
  color: #fff;
  font-size: 1.125rem;
}
.footer__large-text {
  font-size: 1.75rem;
  font-family: "TiltWarp", Poppins, Montserrat, sans-serif;
  font-weight: normal;
}
@media screen and (min-width: 500px) {
  .footer__large-text {
    font-size: 2.125rem;
  }
}
.footer__content {
  display: flex;
  flex-direction: column-reverse;
  text-align: left;
}
@media screen and (min-width: 1400px) {
  .footer__content {
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  }
}
@media screen and (max-width: 51.8125rem) {
  .footer__legal {
    text-align: center;
  }
}
.footer__legal p {
  margin: 0;
}
.footer__login-link {
  position: absolute;
  bottom: 0.5rem;
  left: 0.5rem;
  font-size: 1rem;
  opacity: 0;
}
.footer__login-link, .footer__login-link:link, .footer__login-link:visited {
  color: inherit;
  text-decoration: none;
}
.footer__login-link:hover, .footer__login-link:focus, .footer__login-link:active {
  opacity: 1;
}
.footer__rental {
  position: relative;
  z-index: 2;
  text-align: right;
  padding-top: 250px;
}
@media screen and (min-width: 830px) {
  .footer__rental {
    padding-top: 0;
  }
}
.footer__rental p {
  margin: 0;
}

/*
* DESIGN COMPONENTS
*/
/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
/* MARKUP */
/*
<button class="hamburger" type="button">
  <span class="hamburger-box">
    <span class="hamburger-inner"></span>
  </span>
</button>
 */
.hamburger {
  padding: 0 0;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}
.hamburger:hover {
  opacity: 0.7;
}
.hamburger.is-active:hover {
  opacity: 0.7;
}
.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #fff;
}

.hamburger-box {
  width: 36px;
  height: 25px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1.5px;
}
.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 36px;
  height: 3px;
  background-color: #fff;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}
.hamburger-inner::before {
  top: -11px;
}
.hamburger-inner::after {
  bottom: -11px;
}

/*
 * Collapse
 */
.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--collapse .hamburger-inner::after {
  top: -22px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
}
.hamburger--collapse .hamburger-inner::before {
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--collapse.is-active .hamburger-inner {
  transform: translate3d(0, -11px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--collapse.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
}
.hamburger--collapse.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.button {
  border-radius: 11px;
  padding: 1.125rem 2.875rem;
  font-size: 1.125rem;
  margin: 0;
  font-weight: bold;
  background-color: #b16300;
  display: inline-block;
  color: #fff;
  text-align: center;
  transition: background-color 0.15s ease, box-shadow 0.15s ease;
  position: relative;
  box-shadow: none;
  /* &[disabled] {
  	color: #999999;
  	background-color: #ebebeb;
  	cursor: not-allowed;
  }

  &.-icon {
  	&::before {
  		vertical-align: middle;
  		margin-right: .75em;
  		display: inline-block;
  	}
  }

  &.-primary {
  	background-color: $button-primary-bg;
  	box-shadow: 0 0 0 0 $button-primary-bg;
  	color: $button-primary-fg;

  	&:not([disabled]) {
  		&:hover,
  		&:focus,
  		&:active {
  			box-shadow: 0 5px 20px $button-primary-bg;
  		}
  	}
  } */
}
.button, .button:link, .button:visited {
  color: #fff;
  text-decoration: none;
}
.button:hover, .button:focus, .button:active {
  background-color: #083735;
}
.button:not([disabled]):hover, .button:not([disabled]):focus, .button:not([disabled]):active {
  z-index: 3;
  outline: 0;
}
.button:nth-last-child(n+2), .button:nth-last-child(n+2) ~ .button {
  margin-bottom: 0.5em;
}

.button + .button:not(.-block) {
  margin-left: 1em;
}

.link-button {
  border-radius: 300px;
  padding: 0.85em 3em;
  font-size: 1.125rem;
  margin: 0;
  background-color: #083735;
  color: hsl(26deg, 28%, 95%);
  display: inline-block;
  text-align: center;
  transition: background-color 0.15s ease, color 0.15s ease, box-shadow 0.25s ease;
  position: relative;
  box-shadow: none;
}
.link-button, .link-button:link, .link-button:visited {
  text-decoration: none;
  color: hsl(26deg, 28%, 95%);
}
.link-button:hover, .link-button:focus, .link-button:active {
  background-color: #b16300;
  color: hsl(0deg, 0%, 3%);
}
.link-button--with-shadow, .link-button--with-shadow:link, .link-button--with-shadow:visited {
  box-shadow: 0 10px 30px rgba(8, 55, 53, 0.7);
}
.link-button--with-shadow:hover, .link-button--with-shadow:focus, .link-button--with-shadow:active {
  box-shadow: 0 20px 40px rgba(8, 55, 53, 0.7);
}

@keyframes swipeview-reveal {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.swipeview {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 30999;
  background: rgba(8, 8, 8, 0.85);
  backdrop-filter: blur(5px);
  scroll-behavior: auto;
  display: block;
  opacity: 0;
}
.swipeview:not([data-is-open=true]) {
  visibility: hidden;
}
.swipeview[data-is-open=true] {
  animation: swipeview-reveal 0.4s ease-out forwards 0.1s;
}
.swipeview__list-wrapper {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}
.swipeview__list {
  touch-action: manipulation;
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: nowrap;
  height: calc(100vh + 40px);
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
}
.swipeview__item {
  box-sizing: border-box;
  border: 10px transparent solid;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
  justify-content: center;
  align-items: center;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  scroll-snap-align: start;
  scroll-snap-stop: always;
}
.swipeview__image {
  user-select: none;
  pointer-events: none;
  object-fit: contain;
  max-height: 100%;
  background: transparent url(/assets/images/swipeview/loading.svg) no-repeat center center/100px auto !important;
}
.swipeview__counter-wrapper {
  position: fixed;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
  padding: 10px 22px;
  border-radius: 500px;
  background-color: rgba(8, 8, 8, 0.65);
  color: hsl(26deg, 28%, 95%);
  font-size: 1rem;
}
.swipeview__button {
  position: fixed;
  box-shadow: none;
  outline: 0;
  z-index: 2;
  width: 50px;
  height: 50px;
  -webkit-appearance: none;
  appearance: none;
  padding: 0;
  margin: 0;
  border-radius: 500px;
  transition: background-color 0.18s ease-out;
  background-color: rgba(8, 8, 8, 0.65);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-image: none;
  background-size: 26px;
}
.swipeview__button:not([disabled]) {
  cursor: pointer;
}
.swipeview__button:not([disabled]):hover, .swipeview__button:not([disabled]):focus {
  outline: 0;
  background-color: #083735;
}
@media screen and (min-width: 800px) {
  .swipeview__button {
    width: 70px;
    height: 70px;
  }
}
.swipeview__button[disabled] {
  opacity: 0.3;
}
.swipeview__button--exit {
  right: 10px;
  top: 10px;
  border-radius: 500px;
  background-image: url("data:image/svg+xml;utf8,%3Csvg viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg' width='40' height='40'%3E%3Cpath fill='hsl(26deg, 28%, 95%)' d='M21.422 20l15.287 15.287c.393.393.393 1.029 0 1.422s-1.029.393-1.422 0l-15.287-15.287-15.287 15.287c-.393.393-1.029.393-1.422 0s-.393-1.029 0-1.422l15.287-15.287-15.287-15.287c-.393-.393-.393-1.029 0-1.422s1.029-.393 1.422 0l15.287 15.287 15.287-15.287c.393-.393 1.029-.393 1.422 0s.393 1.029 0 1.422l-15.287 15.287z'/%3E%3C/svg%3E");
}
.swipeview__button--previous, .swipeview__button--next {
  transform: translateY(-50%);
}
.swipeview__button--previous {
  left: 10px;
  top: 50%;
  background-image: url("data:image/svg+xml;utf8,%3Csvg viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg' width='40' height='40'%3E%3Cpath fill='hsl(26deg, 28%, 95%)' d='M8.502 20c-.003-.259.092-.519.289-.716l19.008-19.007c.389-.389 1.02-.389 1.408 0s.389 1.019 0 1.407l-18.315 18.316 18.315 18.315c.389.389.389 1.019 0 1.407s-1.02.389-1.408 0l-19.008-19.006c-.197-.197-.292-.457-.289-.716z'/%3E%3C/svg%3E");
}
.swipeview__button--next {
  right: 10px;
  top: 50%;
  background-image: url("data:image/svg+xml;utf8,%3Csvg viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg' width='40' height='40'%3E%3Cpath fill='hsl(26deg, 28%, 95%)' d='M31.209 20.716l-19.008 19.007c-.389.389-1.019.389-1.407 0s-.389-1.019 0-1.407l18.314-18.316-18.315-18.315c-.389-.389-.389-1.019 0-1.407s1.019-.389 1.407 0l19.008 19.007c.197.197.292.457.289.716.004.258-.091.518-.288.715z'/%3E%3C/svg%3E");
}

.contact-form {
  display: grid;
  position: relative;
  margin-top: -0.88em;
}
.contact-form::after {
  content: "";
  width: 75px;
  height: 75px;
  background: transparent url(/assets/images/svg-loaders/form-loader.svg) no-repeat 0 0/100%;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 10;
  transform: translate(-50%, -50%);
  display: none;
}
.contact-form.is-loading {
  opacity: 0.5;
  pointer-events: none !important;
}
.contact-form.is-loading::after {
  display: block;
}
.contact-form.is-loading * {
  pointer-events: none !important;
}
.contact-form__submit-section {
  text-align: left;
}
.contact-form__section {
  margin-bottom: 1em;
}
.contact-form__label {
  margin: 0 0 0.25em;
  padding: 0;
  display: inline-block;
}
.contact-form__input, .contact-form__textarea {
  appearance: none;
  box-shadow: none;
  border-width: 0;
  border-bottom: 2px white solid;
  background-color: transparent;
  background-image: none;
  color: white;
  width: 100%;
  min-height: 52px;
}
.contact-form__input--has-error, .contact-form__textarea--has-error {
  border-color: rgb(161, 28, 28) !important;
}
.contact-form__input {
  padding: 0.5em 0;
}
.contact-form__textarea {
  resize: vertical;
  min-height: 5rem;
  padding: 0.85em 0;
}
.contact-form__fail-message, .contact-form__success-message, .contact-form__error-message {
  font-size: 0.875rem;
  margin: 0.75em 0 0;
}
.contact-form__success-message {
  color: rgb(64, 160, 45);
}
.contact-form__fail-message, .contact-form__error-message {
  color: rgb(161, 28, 28);
}
.contact-form .h-captcha {
  text-align: left;
  margin-bottom: 20px;
}
.contact-form .button.contact-form__button {
  background-color: transparent;
  border: 2px white solid;
  font-size: 0.875rem;
  font-weight: bold;
  padding-top: 0.4375rem;
  padding-bottom: 0.6875rem;
  text-transform: lowercase;
}

.pagination {
  display: inline-flex;
  margin: initial auto;
}
.pagination__list {
  padding: 0;
  margin: 1em 0;
  list-style-type: none;
  text-align: center;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
}
.gallery {
  display: grid;
  grid-gap: 38px 20px;
  gap: 38px 20px;
}
@media screen and (min-width: 360px) {
  .gallery {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 600px) {
  .gallery {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (min-width: 800px) {
  .gallery {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 1024px) {
  .gallery {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (min-width: 1280px) {
  .gallery {
    grid-template-columns: repeat(5, 1fr);
    margin-bottom: 15%;
  }
}
.gallery__item {
  display: flex;
  flex-direction: column;
  position: relative;
}
@media screen and (min-width: 37.5rem) and (max-width: 49.9375rem) {
  .gallery__item:nth-child(2) {
    transform: translateY(50%);
  }
  .gallery__item:nth-child(5) {
    transform: translateY(50%);
  }
  .gallery__item:nth-child(8) {
    transform: translateY(50%);
  }
  .gallery__item:nth-child(11) {
    transform: translateY(50%);
  }
  .gallery__item:nth-child(14) {
    transform: translateY(50%);
  }
  .gallery__item:nth-child(17) {
    transform: translateY(50%);
  }
  .gallery__item:nth-child(20) {
    transform: translateY(50%);
  }
  .gallery__item:nth-child(23) {
    transform: translateY(50%);
  }
  .gallery__item:nth-child(26) {
    transform: translateY(50%);
  }
  .gallery__item:nth-child(29) {
    transform: translateY(50%);
  }
}
@media screen and (min-width: 64rem) and (max-width: 79.9375rem) {
  .gallery__item:nth-child(2) {
    transform: translateY(50%);
  }
  .gallery__item:nth-child(5) {
    transform: translateY(50%);
  }
  .gallery__item:nth-child(8) {
    transform: translateY(50%);
  }
  .gallery__item:nth-child(11) {
    transform: translateY(50%);
  }
  .gallery__item:nth-child(14) {
    transform: translateY(50%);
  }
  .gallery__item:nth-child(17) {
    transform: translateY(50%);
  }
  .gallery__item:nth-child(20) {
    transform: translateY(50%);
  }
  .gallery__item:nth-child(23) {
    transform: translateY(50%);
  }
  .gallery__item:nth-child(26) {
    transform: translateY(50%);
  }
  .gallery__item:nth-child(29) {
    transform: translateY(50%);
  }
}
@media screen and (min-width: 1280px) {
  .gallery__item:nth-child(2) {
    transform: translateY(50%);
  }
  .gallery__item:nth-child(4) {
    transform: translateY(50%);
  }
  .gallery__item:nth-child(7) {
    transform: translateY(50%);
  }
  .gallery__item:nth-child(9) {
    transform: translateY(50%);
  }
  .gallery__item:nth-child(12) {
    transform: translateY(50%);
  }
  .gallery__item:nth-child(14) {
    transform: translateY(50%);
  }
  .gallery__item:nth-child(17) {
    transform: translateY(50%);
  }
  .gallery__item:nth-child(19) {
    transform: translateY(50%);
  }
  .gallery__item:nth-child(22) {
    transform: translateY(50%);
  }
  .gallery__item:nth-child(24) {
    transform: translateY(50%);
  }
  .gallery__item:nth-child(27) {
    transform: translateY(50%);
  }
}
.gallery__link {
  line-height: 0;
  display: block;
}
.gallery__image {
  width: 100%;
  aspect-ratio: 130/142;
  mask: url(/assets/images/calendar-mask.svg);
  mask-size: 100% 100%;
  mask-position: 0 0;
  mask-repeat: no-repeat;
  object-fit: cover;
  object-position: center;
}

.booking {
  text-align: center;
  max-width: 568px;
  margin-left: auto;
}
.booking__heading {
  font-size: 2.125rem;
  margin-bottom: 0.75rem;
}
.booking__subheading {
  margin: 0 0 2.5rem;
  font-size: 1.125rem;
}

.calendar {
  color: white;
  min-height: 31.25rem;
  background-color: #083735;
  mask: url(/assets/images/calendar-mask.svg);
  mask-size: 100% 100%;
  mask-position: 0 0;
  mask-repeat: no-repeat;
  padding: 1.5625rem 1.875rem;
}
@media screen and (min-width: 1600px) {
  .calendar {
    padding: 3.75rem 5rem;
  }
}

.contact {
  background-color: #083735;
  background-image: url(/assets/images/waves-brand-accent.svg);
  background-repeat: no-repeat;
  background-position: 50% 100%;
  color: white;
  padding: 5.625rem 0;
}
[data-view=home] .contact {
  padding: 8.75rem 0;
  background-image: url(/assets/images/waves-top.svg), url(/assets/images/waves-brand-accent.svg);
  background-position: 50% 0, 50% 100%;
}
.contact .button:hover, .contact .button:focus, .contact .button:active {
  background-color: #062b29;
}
.contact__heading {
  color: inherit;
  font-size: 2.125rem;
  margin: 0 0 2.25rem;
}
.contact__content {
  display: grid;
  grid-gap: 40px;
  gap: 40px;
}
@media screen and (min-width: 800px) {
  .contact__content {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (min-width: 1280px) {
  .contact__content {
    grid-template-columns: 36.25rem 1fr 1fr;
  }
}

.contact-info__content {
  font-size: 1.125rem;
  display: grid;
  grid-gap: 30px;
  gap: 30px;
  grid-template-columns: 1fr 1fr;
}
.contact-info__content p {
  margin: 0 0 0.5em;
}
.contact-info__legal {
  grid-column: 1/span 2;
  font-size: 0.875rem;
}

.car-rental {
  width: 320px;
  height: 320px;
  overflow: hidden;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
}
@media screen and (min-width: 1422px) {
  .car-rental {
    width: 650px;
    height: 650px;
  }
}
.car-rental::before {
  content: "";
  position: absolute;
  width: 425px;
  height: 430px;
  left: 31px;
  position: absolute;
  top: 2px;
  border: 4px #b16300 solid;
  border-radius: 50%;
}
@media screen and (min-width: 1422px) {
  .car-rental::before {
    left: 165px;
    top: 119px;
    width: 654px;
    height: 643px;
  }
}
.car-rental__picture {
  position: absolute;
  right: -98px;
  bottom: -95px;
}
@media screen and (min-width: 1422px) {
  .car-rental__picture {
    right: -98px;
    bottom: -90px;
  }
}
.car-rental__picture img {
  border-radius: 50%;
  width: 380px;
  height: 380px;
}
@media screen and (min-width: 1422px) {
  .car-rental__picture img {
    width: 576px;
    height: 576px;
  }
}

.calendar .booking-city-diary {
  color: white !important;
  min-height: initial !important;
  padding: 0 !important;
  margin: 0 !important;
}
.calendar .booking-city-diary .content-city-diary {
  font-family: "Jost", system-ui, sans-serif !important;
}
.calendar .booking-city-diary h1,
.calendar .booking-city-diary h2,
.calendar .booking-city-diary h3,
.calendar .booking-city-diary h4,
.calendar .booking-city-diary p,
.calendar .booking-city-diary a,
.calendar .booking-city-diary span:not(.fc-icon),
.calendar .booking-city-diary strong,
.calendar .booking-city-diary small,
.calendar .booking-city-diary label {
  font-family: inherit !important;
}
.calendar .booking-city-diary .fc-daygrid-day-events::after,
.calendar .booking-city-diary .fc-daygrid-day-events::before,
.calendar .booking-city-diary .fc-daygrid-day-frame::after,
.calendar .booking-city-diary .fc-daygrid-day-frame::before,
.calendar .booking-city-diary .fc-daygrid-event-harness::after,
.calendar .booking-city-diary .fc-daygrid-event-harness::before {
  content: normal !important;
}
.calendar .booking-city-diary .fc-scrollgrid-section-header.fc-scrollgrid-section-sticky {
  position: relative !important;
}
.calendar .booking-city-diary .fc-scrollgrid-section-header.fc-scrollgrid-section-sticky .fc-scroller-harness {
  border-bottom: 4px #305f5d solid !important;
  margin: 0 0 15px !important;
  padding: 0 0 15px !important;
}
.calendar .booking-city-diary .fc-col-header {
  margin: 0 !important;
  padding: 0 !important;
}
.calendar .booking-city-diary .fc-daygrid-day-top:hover + .fc-daygrid-day-events:empty ~ .fc-daygrid-day-bg:empty::before, .calendar .booking-city-diary .fc-daygrid-day-top:focus-within + .fc-daygrid-day-events:empty ~ .fc-daygrid-day-bg:empty::before {
  content: "" !important;
  background-color: transparent !important;
  border: 2px white solid !important;
  border-radius: 4px !important;
  position: absolute !important;
  left: 2px !important;
  right: 2px !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  height: 1.5rem !important;
  display: block !important;
  visibility: visible !important;
  pointer-events: none !important;
  box-sizing: border-box !important;
}
.calendar .booking-city-diary .fc-daygrid-day-number, .calendar .booking-city-diary .fc-daygrid-day-number:link, .calendar .booking-city-diary .fc-daygrid-day-number:visited {
  width: 100% !important;
  display: block !important;
  text-align: center !important;
}
.calendar .booking-city-diary .fc .fc-daygrid-day-top {
  justify-content: center !important;
  align-items: center !important;
  position: relative !important;
  z-index: 5 !important;
}
.calendar .booking-city-diary a:not([href]), .calendar .booking-city-diary a:not([href]):link, .calendar .booking-city-diary a:not([href]):visited, .calendar .booking-city-diary a:not([href]):hover, .calendar .booking-city-diary a:not([href]):focus, .calendar .booking-city-diary a:not([href]):active {
  text-decoration: none !important;
}
.calendar .booking-city-diary .fc-daygrid-day-events:not(:empty) .fc-daygrid-event-harness::before {
  content: "" !important;
  border: 2px #b16300 solid !important;
  border-radius: 4px !important;
  position: absolute !important;
  left: 2px !important;
  right: 2px !important;
  top: -14px !important;
  transform: translateY(-50%) !important;
  height: 1.5rem !important;
  display: block !important;
  visibility: visible !important;
  pointer-events: none !important;
  box-sizing: border-box !important;
}
.calendar .booking-city-diary .fc .fc-daygrid-day-bg .fc-highlight {
  background-color: transparent !important;
}
.calendar .booking-city-diary .fc .fc-daygrid-day-bg .fc-highlight::before {
  content: "" !important;
  background-color: #b16300 !important;
  border: 2px #b16300 solid !important;
  border-radius: 4px !important;
  position: absolute !important;
  left: 2px !important;
  right: 2px !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  height: 1.5rem !important;
  display: block !important;
  visibility: visible !important;
  pointer-events: none !important;
  box-sizing: border-box !important;
}
.calendar .booking-city-diary .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  min-height: initial !important;
}
.calendar .booking-city-diary h2 {
  color: inherit;
}
.calendar .booking-city-diary .booking-step-content-city-diary {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.calendar .booking-city-diary .fc-toolbar-title {
  font-family: "TiltWarp", Poppins, Montserrat, sans-serif !important;
  text-transform: uppercase !important;
  text-align: center !important;
}
.calendar .booking-city-diary .fc-dayGridMonth-button.fc-button.fc-button-primary,
.calendar .booking-city-diary .fc .fc-header-toolbar .fc-today-button.fc-button-primary,
.calendar .booking-city-diary .booking-steps-list-city-diary {
  display: none !important;
}
.calendar .booking-city-diary .fc-theme-standard .fc-scrollgrid,
.calendar .booking-city-diary .fc-theme-standard td,
.calendar .booking-city-diary .fc-theme-standard th,
.calendar .booking-city-diary .fc-liquid-hack .fc-daygrid-day-frame,
.calendar .booking-city-diary .fc-timegrid-col,
.calendar .booking-city-diary .fc-col-header-cell {
  border: 0 !important;
}
.calendar .booking-city-diary .fc .fc-daygrid-bg-harness {
  z-index: 1 !important;
}
.calendar .booking-city-diary .fc-liquid-hack td,
.calendar .booking-city-diary .fc-liquid-hack th {
  z-index: 2 !important;
}
.calendar .booking-city-diary .fc-daygrid-event.fc-daygrid-block-event.fc-h-event.fc-event {
  background-color: transparent !important;
  border: 0 !important;
  margin: 0 !important;
  height: 0 !important;
  padding: 0 !important;
  color: transparent !important;
}
.calendar .booking-city-diary .fc-daygrid-event.fc-daygrid-block-event.fc-h-event.fc-event .fc-event-title.fc-sticky {
  color: transparent !important;
}
.calendar .booking-city-diary .fc-bg-event.fc-event.fc-event-future::after,
.calendar .booking-city-diary .fc-bg-event.fc-event.collision-event-background::after {
  content: "";
  background-color: #ca4526;
  border-radius: 4px;
  position: absolute;
  left: 2px;
  right: 2px;
  top: 50%;
  transform: translateY(-50%);
  height: 1.5em;
}
.calendar .booking-city-diary .fc .fc-daygrid-body-natural .fc-daygrid-day-events {
  margin-bottom: 0 !important;
}
.calendar .booking-city-diary .fc table a {
  color: white !important;
}
.calendar .booking-city-diary .content-city-diary {
  min-height: initial !important;
}
.calendar .booking-city-diary .icheckbox_line-pink.checked,
.calendar .booking-city-diary .iradio_line-pink.checked,
.calendar .booking-city-diary .fc .fc-header-toolbar .fc-button-primary,
.calendar .booking-city-diary button.craue_formflow_button_last,
.calendar .booking-city-diary .icheckbox_line-pink.hover,
.calendar .booking-city-diary .icheckbox_line-pink.checked.hover,
.calendar .booking-city-diary .iradio_line-pink.hover {
  background-color: #b16300 !important;
}
.calendar .booking-city-diary .fc .fc-header-toolbar .fc-button-primary {
  border-color: #b16300 !important;
}
.calendar .booking-city-diary .fc-header-toolbar.fc-toolbar.fc-toolbar-ltr {
  display: grid;
  grid-template-columns: 25px 1fr 25px;
}
@media (max-width: 768px) {
  .calendar .booking-city-diary .fc-header-toolbar.fc-toolbar.fc-toolbar-ltr {
    padding-top: 0 !important;
  }
}
@media (max-width: 768px) {
  .calendar .booking-city-diary .fc-header-toolbar.fc-toolbar.fc-toolbar-ltr .fc-toolbar-chunk.fc-toolbar-chunk:nth-child(2) {
    font-size: 1.25rem !important;
    position: static !important;
  }
  .calendar .booking-city-diary .fc-header-toolbar.fc-toolbar.fc-toolbar-ltr .fc-toolbar-chunk.fc-toolbar-chunk:nth-child(2) .fc-toolbar-title {
    font-size: 1.25rem !important;
  }
  .calendar .booking-city-diary .fc-header-toolbar.fc-toolbar.fc-toolbar-ltr .fc-toolbar-chunk.fc-toolbar-chunk:nth-child(2) i.fas {
    display: none !important;
  }
}
.calendar .booking-city-diary .fc-header-toolbar.fc-toolbar.fc-toolbar-ltr .fc-toolbar-chunk:first-child {
  grid-row: 1;
  grid-column: 1/span 3;
}
.calendar .booking-city-diary .fc-header-toolbar.fc-toolbar.fc-toolbar-ltr .fc-toolbar-chunk:first-child .fc-button-group {
  display: flex;
  justify-content: space-between;
}
.calendar .booking-city-diary .fc-header-toolbar.fc-toolbar.fc-toolbar-ltr .fc-toolbar-chunk:first-child .fc-button-group .fc-button-primary {
  flex-grow: 0 !important;
  background-color: transparent !important;
  border: 0 !important;
  padding: 0 !important;
}
.calendar .booking-city-diary .fc-header-toolbar.fc-toolbar.fc-toolbar-ltr .fc-toolbar-chunk:first-child + .fc-toolbar-chunk {
  grid-row: 1;
  grid-column: 2;
}
.calendar .booking-city-diary .fc-header-toolbar.fc-toolbar.fc-toolbar-ltr .fc-toolbar-chunk:last-child {
  display: none;
}
.calendar .booking-city-diary .fc .fc-bg-event,
.calendar .booking-city-diary .fc .fc-bg-event tr,
.calendar .booking-city-diary .fc .fc-bg-event th,
.calendar .booking-city-diary .fc .fc-bg-event td,
.calendar .booking-city-diary table,
.calendar .booking-city-diary table tr,
.calendar .booking-city-diary table th,
.calendar .booking-city-diary table td {
  background-color: transparent !important;
}
.calendar .booking-city-diary .fc .fc-bg-event.collision-event-background {
  background-color: transparent !important;
}
.calendar .booking-city-diary .platba-header-bar span i.fa-dot-circle,
.calendar .booking-city-diary .craue_formflow_current_step,
.calendar .booking-city-diary .footer-link-city-diary {
  color: #b16300 !important;
}