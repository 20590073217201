.gallery {
	display: grid;
	grid-gap: 38px 20px;
	gap: 38px 20px;

	@include min(360px) {
		grid-template-columns: repeat(2, 1fr);
	}

	@include min(600px) {
		grid-template-columns: repeat(3, 1fr);
	}

	@include min(800px) {
		grid-template-columns: repeat(2, 1fr);
	}

	@include min(1024px) {
		grid-template-columns: repeat(3, 1fr);
	}

	@include min(1280px) {
		grid-template-columns: repeat(5, 1fr);
		margin-bottom: 15%;
	}

	&__item {
		display: flex;
		flex-direction: column;
		position: relative;

		@include minmax(600px, 799px) {
			@each $i in (2, 5, 8, 11, 14, 17, 20, 23, 26, 29) {
				&:nth-child(#{$i}) {
					transform: translateY(50%);
				}
			}
		}

		@include minmax(1024px, 1279px) {
			@each $i in (2, 5, 8, 11, 14, 17, 20, 23, 26, 29) {
				&:nth-child(#{$i}) {
					transform: translateY(50%);
				}
			}
		}

		@include min(1280px) {
			@each $i in (2, 4, 7, 9, 12, 14, 17, 19, 22, 24, 27) {
				&:nth-child(#{$i}) {
					transform: translateY(50%);
				}
			}
		}
	}

	&__link {
		line-height: 0;
		display: block;
	}

	&__image {
		width: 100%;
		aspect-ratio: 130 / 142;
		mask: url(/assets/images/calendar-mask.svg);
		mask-size: 100% 100%;
		mask-position: 0 0;
		mask-repeat: no-repeat;
		object-fit: cover;
		object-position: center;
	}
}
