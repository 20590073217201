.contact-form {
	display: grid;
	position: relative;
	margin-top: -0.88em;

	&::after {
		content: "";
		width: 75px;
		height: 75px;
		background: transparent url(#{$imagePath}svg-loaders/form-loader.svg)
			no-repeat 0 0 / 100%;
		position: absolute;
		left: 50%;
		top: 50%;
		z-index: 10;
		transform: translate(-50%, -50%);
		display: none;
	}

	&.is-loading {
		opacity: 0.5;
		pointer-events: none !important;

		&::after {
			display: block;
		}

		* {
			pointer-events: none !important;
		}
	}

	&__submit-section {
		text-align: left;
	}

	&__section {
		margin-bottom: 1em;
	}

	&__label {
		margin: 0 0 0.25em;
		padding: 0;
		display: inline-block;
	}

	&__input,
	&__textarea {
		appearance: none;
		box-shadow: none;
		border-width: 0;
		border-bottom: 2px white solid;
		background-color: transparent;
		background-image: none;
		color: white;
		width: 100%;
		min-height: $form-item-standard-size;

		&--has-error {
			border-color: rgb(161, 28, 28) !important;
		}
	}

	&__input {
		padding: 0.5em 0;
	}

	&__textarea {
		resize: vertical;
		min-height: 5rem;
		padding: 0.85em 0;
	}

	&__fail-message,
	&__success-message,
	&__error-message {
		font-size: rem(14px);
		margin: 0.75em 0 0;
	}

	&__success-message {
		color: rgb(64, 160, 45);
	}

	&__fail-message,
	&__error-message {
		color: rgb(161, 28, 28);
	}

	.h-captcha {
		text-align: left;
		margin-bottom: 20px;
	}

	// &:focus-within {
	// 	.h-captcha {
	// 		display: block;
	// 	}
	// }

	.button.contact-form__button {
		background-color: transparent;
		border: 2px white solid;
		font-size: rem(14px);
		font-weight: bold;
		padding-top: rem(7px);
		padding-bottom: rem(11px);
		text-transform: lowercase;
	}
}
