.pagination {
	display: inline-flex;
	margin: initial auto;

	&__list {
		padding: 0;
		margin: 1em 0;
		list-style-type: none;
		text-align: center;
		align-items: center;
		display: grid;
		grid-template-columns: 1fr auto 1fr;
	}

	&__item {
		&--previous {
		}

		&--next {
		}
	}

	&__link {
	}
}
