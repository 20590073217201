.footer {
	text-align: center;
	margin-top: auto;
	padding-top: 2em;
	padding-bottom: 2em;
	position: relative;
	background-color: $color-brand-accent;
	color: #fff;
	font-size: rem(18px);

	&__large-text {
		font-size: rem(28px);
		font-family: $font-headings;
		font-weight: normal;

		@include min(500px) {
			font-size: rem(34px);
		}
	}

	&__content {
		display: flex;
		flex-direction: column-reverse;
		text-align: left;

		@include min(1400px) {
			justify-content: space-between;
			//flex-direction: row-reverse;
			flex-direction: row;
			align-items: center;
		}
	}

	&__legal {
		@include max(829px) {
			text-align: center;
		}
		p {
			margin: 0;
		}
	}

	&__login-link {
		position: absolute;
		bottom: 0.5rem;
		left: 0.5rem;
		font-size: 1rem;
		opacity: 0;

		@include link(&) {
			color: inherit;
			text-decoration: none;
		}

		@include link-over(&) {
			opacity: 1;
		}
	}

	&__rental {
		position: relative;
		z-index: 2;
		text-align: right;
		padding-top: 250px;

		@include min(830px) {
			padding-top: 0;
		}

		p {
			margin: 0;
		}
	}
}
