@keyframes swipeview-reveal {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.swipeview {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 30999;
	background: rgba($color-dark, 0.85);
	backdrop-filter: blur(5px);
	scroll-behavior: auto;
	display: block;
	opacity: 0;

	&:not([data-is-open="true"]) {
		visibility: hidden;
	}

	&[data-is-open="true"] {
		animation: swipeview-reveal 0.4s ease-out forwards 0.1s;
	}

	&__list-wrapper {
		position: fixed;
		overflow: hidden;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
	}

	&__list {
		touch-action: manipulation;
		padding: 0;
		margin: 0;
		list-style-type: none;
		display: flex;
		flex-wrap: nowrap;
		height: calc(100vh + 40px);
		overflow-x: scroll;
		scroll-snap-type: x mandatory;
	}

	&__item {
		box-sizing: border-box;
		border: 10px transparent solid;
		width: 100vw;
		height: 100vh;
		display: flex;
		flex-shrink: 0;
		flex-grow: 0;
		justify-content: center;
		align-items: center;
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: contain;
		scroll-snap-align: start;
		scroll-snap-stop: always;
	}

	&__image {
		user-select: none;
		pointer-events: none;
		object-fit: contain;
		max-height: 100%;
		background: transparent url(#{$imagePath}swipeview/loading.svg) no-repeat
			center center / 100px auto !important;
	}

	&__counter-wrapper {
		position: fixed;
		left: 50%;
		bottom: 20px;
		transform: translateX(-50%);
		padding: 10px 22px;
		border-radius: 500px;
		background-color: rgba($color-dark, 0.65);
		color: $color-light;
		font-size: 1rem;
	}

	&__button {
		position: fixed;
		box-shadow: none;
		outline: 0;
		z-index: 2;
		width: 50px;
		height: 50px;
		-webkit-appearance: none;
		appearance: none;
		padding: 0;
		margin: 0;
		border-radius: 500px;
		transition: background-color 0.18s ease-out;
		background: {
			color: rgba($color-dark, 0.65);
			repeat: no-repeat;
			position: 50% 50%;
			image: none;
			size: 26px;
		}

		&:not([disabled]) {
			cursor: pointer;

			&:hover,
			&:focus {
				outline: 0;
				background-color: $color-brand;
			}
		}

		@include min(800px) {
			width: 70px;
			height: 70px;
		}
	}

	&__button[disabled] {
		opacity: 0.3;
		//pointer-events: none;
	}

	&__button--exit {
		right: 10px;
		top: 10px;
		border-radius: 500px;
		background-image: encode-svg(
			'<svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" width="40" height="40"><path fill="#{$color-light}" d="M21.422 20l15.287 15.287c.393.393.393 1.029 0 1.422s-1.029.393-1.422 0l-15.287-15.287-15.287 15.287c-.393.393-1.029.393-1.422 0s-.393-1.029 0-1.422l15.287-15.287-15.287-15.287c-.393-.393-.393-1.029 0-1.422s1.029-.393 1.422 0l15.287 15.287 15.287-15.287c.393-.393 1.029-.393 1.422 0s.393 1.029 0 1.422l-15.287 15.287z"/></svg>'
		);
	}

	&__button--previous,
	&__button--next {
		transform: translateY(-50%);
	}

	&__button--previous {
		left: 10px;
		top: 50%;
		background-image: encode-svg(
			'<svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" width="40" height="40"><path fill="#{$color-light}" d="M8.502 20c-.003-.259.092-.519.289-.716l19.008-19.007c.389-.389 1.02-.389 1.408 0s.389 1.019 0 1.407l-18.315 18.316 18.315 18.315c.389.389.389 1.019 0 1.407s-1.02.389-1.408 0l-19.008-19.006c-.197-.197-.292-.457-.289-.716z"/></svg>'
		);
	}

	&__button--next {
		right: 10px;
		top: 50%;
		background-image: encode-svg(
			'<svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" width="40" height="40"><path fill="#{$color-light}" d="M31.209 20.716l-19.008 19.007c-.389.389-1.019.389-1.407 0s-.389-1.019 0-1.407l18.314-18.316-18.315-18.315c-.389-.389-.389-1.019 0-1.407s1.019-.389 1.407 0l19.008 19.007c.197.197.292.457.289.716.004.258-.091.518-.288.715z"/></svg>'
		);
	}
}
