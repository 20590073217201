/*
* BASE DEPENDENCIES
*/

@import "abstracts/mixins", "abstracts/functions", "abstracts/vars",
	"abstracts/animations", "abstracts/templates";

/*
* COMMON BLOCKS
*/

@import "layout/footer";

/*
* DESIGN COMPONENTS
*/

@import //"components/search-form",
	//"components/search",
	"components/hamburgers/hamburgers",
	//"components/table",
	"components/button",
	"components/link-button", "components/swipeview", "components/contact-form",
	"components/pagination", "components/gallery";

.booking {
	text-align: center;
	max-width: 568px;
	margin-left: auto;

	&__heading {
		font-size: rem(34px);
		margin-bottom: rem(12px);
	}

	&__subheading {
		margin: 0 0 rem(40px);
		font-size: rem(18px);
	}
}

.calendar {
	color: white;
	min-height: rem(500px);
	background-color: $color-brand;
	mask: url(/assets/images/calendar-mask.svg);
	mask-size: 100% 100%;
	mask-position: 0 0;
	mask-repeat: no-repeat;
	padding: rem(25px) rem(30px);

	@include min(1600px) {
		padding: rem(60px) rem(80px);
	}
}

.contact {
	background: {
		color: $color-brand;
		image: url(/assets/images/waves-brand-accent.svg);
		repeat: no-repeat;
		position: 50% 100%;
	}
	color: white;
	padding: rem(90px) 0;

	[data-view="home"] & {
		padding: rem(140px) 0;
		background-image: url(/assets/images/waves-top.svg),
			url(/assets/images/waves-brand-accent.svg);
		background-position: 50% 0, 50% 100%;
	}

	.button {
		@include link-over(&) {
			background-color: $color-brand-accent;
		}
	}

	&__heading {
		color: inherit;
		font-size: rem(34px);
		margin: 0 0 rem(36px);
	}

	&__content {
		display: grid;
		grid-gap: 40px;
		gap: 40px;

		@include min(800px) {
			grid-template-columns: 1fr 1fr;
		}

		@include min(1280px) {
			grid-template-columns: rem(580px) 1fr 1fr;
		}
	}
}

.contact-info {
	&__content {
		font-size: rem(18px);
		display: grid;
		grid-gap: 30px;
		gap: 30px;
		grid-template-columns: 1fr 1fr;

		p {
			margin: 0 0 0.5em;
		}
	}

	&__legal {
		grid-column: 1 / span 2;
		font-size: rem(14px);
	}
}

.car-rental {
	width: 320px;
	height: 320px;
	overflow: hidden;
	position: absolute;
	right: 0;
	bottom: 0;
	pointer-events: none;

	@include min($max-width) {
		width: 650px;
		height: 650px;
	}

	&::before {
		content: "";
		position: absolute;
		width: 425px;
		height: 430px;
		left: 31px;
		position: absolute;
		top: 2px;
		border: 4px $color-secondary solid;
		border-radius: 50%;

		@include min($max-width) {
			left: 165px;
			top: 119px;
			width: 654px;
			height: 643px;
		}
	}

	&__picture {
		position: absolute;
		right: -98px;
		bottom: -95px;

		@include min($max-width) {
			right: -98px;
			bottom: -90px;
		}

		img {
			border-radius: 50%;
			width: 380px;
			height: 380px;

			@include min($max-width) {
				width: 576px;
				height: 576px;
			}
		}
	}
}

// Override CityDiář
.calendar {
	.booking-city-diary {
		color: white !important;
		min-height: initial !important;
		padding: 0 !important;
		margin: 0 !important;

		.content-city-diary {
			font-family: $font-body !important;
		}

		h1,
		h2,
		h3,
		h4,
		p,
		a,
		span:not(.fc-icon),
		strong,
		small,
		label {
			font-family: inherit !important;
		}

		.fc-daygrid-day-events::after,
		.fc-daygrid-day-events::before,
		.fc-daygrid-day-frame::after,
		.fc-daygrid-day-frame::before,
		.fc-daygrid-event-harness::after,
		.fc-daygrid-event-harness::before {
			content: normal !important;
		}

		.fc-scrollgrid-section-header.fc-scrollgrid-section-sticky {
			position: relative !important;

			.fc-scroller-harness {
				border-bottom: 4px #305f5d solid !important;
				margin: 0 0 15px !important;
				padding: 0 0 15px !important;
			}
		}

		.fc-col-header {
			margin: 0 !important;
			padding: 0 !important;
		}

		.fc-daygrid-day-top {
			&:hover,
			&:focus-within {
				+ .fc-daygrid-day-events:empty ~ .fc-daygrid-day-bg:empty {
					&::before {
						content: "" !important;
						background-color: transparent !important;
						border: 2px white solid !important;
						border-radius: 4px !important;
						position: absolute !important;
						left: 2px !important;
						right: 2px !important;
						top: 50% !important;
						transform: translateY(-50%) !important;
						height: 1.5rem !important;
						display: block !important;
						visibility: visible !important;
						pointer-events: none !important;
						box-sizing: border-box !important;
					}
				}
			}
		}

		.fc-daygrid-day-number {
			@include link(&) {
				width: 100% !important;
				display: block !important;
				text-align: center !important;
			}
			@include link-over(&) {
			}
		}

		.fc .fc-daygrid-day-top {
			justify-content: center !important;
			align-items: center !important;
			position: relative !important;
			z-index: 5 !important;
		}

		a:not([href]) {
			@include link-all(&) {
				text-decoration: none !important;
			}
		}

		.fc-daygrid-day-events:not(:empty) .fc-daygrid-event-harness {
			&::before {
				content: "" !important;
				border: 2px $color-secondary solid !important;
				border-radius: 4px !important;
				position: absolute !important;
				left: 2px !important;
				right: 2px !important;
				top: -14px !important;
				transform: translateY(-50%) !important;
				height: 1.5rem !important;
				display: block !important;
				visibility: visible !important;
				pointer-events: none !important;
				box-sizing: border-box !important;
			}
		}

		.fc .fc-daygrid-day-bg .fc-highlight {
			background-color: transparent !important;
			&::before {
				content: "" !important;
				background-color: $color-secondary !important;
				border: 2px $color-secondary solid !important;
				border-radius: 4px !important;
				position: absolute !important;
				left: 2px !important;
				right: 2px !important;
				top: 50% !important;
				transform: translateY(-50%) !important;
				height: 1.5rem !important;
				display: block !important;
				visibility: visible !important;
				pointer-events: none !important;
				box-sizing: border-box !important;
			}
		}

		.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
			min-height: initial !important;
		}

		h2 {
			color: inherit;
		}

		.booking-step-content-city-diary {
			padding-left: 0 !important;
			padding-right: 0 !important;
		}

		.fc-toolbar-title {
			font-family: $font-headings !important;
			text-transform: uppercase !important;
			text-align: center !important;
		}

		.fc-dayGridMonth-button.fc-button.fc-button-primary,
		.fc .fc-header-toolbar .fc-today-button.fc-button-primary,
		.booking-steps-list-city-diary {
			display: none !important;
		}

		.fc-theme-standard .fc-scrollgrid,
		.fc-theme-standard td,
		.fc-theme-standard th,
		.fc-liquid-hack .fc-daygrid-day-frame,
		.fc-timegrid-col,
		.fc-col-header-cell {
			border: 0 !important;
		}

		.fc .fc-daygrid-bg-harness {
			z-index: 1 !important;
		}

		.fc-liquid-hack td,
		.fc-liquid-hack th {
			z-index: 2 !important;
		}

		.fc-daygrid-event.fc-daygrid-block-event.fc-h-event.fc-event {
			background-color: transparent !important;
			border: 0 !important;
			margin: 0 !important;
			height: 0 !important;
			padding: 0 !important;
			//margin-top: -27px !important;
			//margin-top: -12px !important;
			color: transparent !important;

			.fc-event-title.fc-sticky {
				color: transparent !important;
			}
		}

		.fc-bg-event.fc-event.fc-event-future,
		.fc-bg-event.fc-event.collision-event-background {
			&::after {
				content: "";
				background-color: #ca4526;
				border-radius: 4px;
				position: absolute;
				left: 2px;
				right: 2px;
				top: 50%;
				transform: translateY(-50%);
				height: 1.5em;
			}
		}

		.fc .fc-daygrid-body-natural .fc-daygrid-day-events {
			margin-bottom: 0 !important;
		}

		.fc table a {
			color: white !important;
		}

		.content-city-diary {
			min-height: initial !important;
		}

		.icheckbox_line-pink.checked,
		.iradio_line-pink.checked,
		.fc .fc-header-toolbar .fc-button-primary,
		button.craue_formflow_button_last,
		.icheckbox_line-pink.hover,
		.icheckbox_line-pink.checked.hover,
		.iradio_line-pink.hover {
			background-color: $color-secondary !important;
		}

		.fc .fc-header-toolbar .fc-button-primary {
			border-color: $color-secondary !important;
		}

		.fc-header-toolbar.fc-toolbar.fc-toolbar-ltr {
			display: grid;
			grid-template-columns: 25px 1fr 25px;

			@media (max-width: 768px) {
				padding-top: 0 !important;
			}

			.fc-toolbar-chunk {
				@media (max-width: 768px) {
					&.fc-toolbar-chunk:nth-child(2) {
						font-size: rem(20px) !important;
						position: static !important;

						.fc-toolbar-title {
							font-size: rem(20px) !important;
						}

						i.fas {
							display: none !important;
						}
					}
				}
				&:first-child {
					grid-row: 1;
					grid-column: 1 / span 3;

					.fc-button-group {
						display: flex;
						justify-content: space-between;

						.fc-button-primary {
							flex-grow: 0 !important;
							background-color: transparent !important;
							border: 0 !important;
							padding: 0 !important;
						}
					}
				}
				&:first-child + .fc-toolbar-chunk {
					grid-row: 1;
					grid-column: 2;
				}

				&:last-child {
					display: none;
				}
			}
		}

		.fc .fc-bg-event,
		table {
			&,
			tr,
			th,
			td {
				background-color: transparent !important;
			}
		}

		.fc .fc-bg-event.collision-event-background {
			background-color: transparent !important;
		}

		.platba-header-bar span i.fa-dot-circle,
		.craue_formflow_current_step,
		.footer-link-city-diary {
			color: $color-secondary !important;
		}
	}
}
