/*==============================
	FONTS
==============================*/

$font-monospace: SFMono-Regular, "Roboto Mono", Consolas, "Liberation Mono",
	Menlo, Courier, monospace;

$font-body-name: "Jost";
$font-headings-name: "TiltWarp";

$font-body: $font-body-name, system-ui, sans-serif;
$font-headings: $font-headings-name, Poppins, Montserrat, sans-serif;

$imagePath: "/assets/images/";
$fontPath: "/assets/fonts/";

/*==============================
	COLORS
==============================*/

$color-base: #7a7a7a;
$color-brand: #083735;
$color-brand-accent: #062b29;
$color-secondary: #b16300;
$color-light: hsl(26, 28%, 95%);
$color-dark: hsl(0, 0%, 3%);

$color-neutral-100: hsl(0, 0%, 97%);
$color-neutral-200: hsl(0, 0%, 95%);
$color-neutral-300: hsl(0, 0%, 89%);
$color-neutral-400: hsl(0, 0%, 79%);
$color-neutral-500: hsl(0, 0%, 70%);
$color-neutral-600: hsl(0, 0%, 49%);
$color-neutral-700: hsl(0, 0%, 35%);
$color-neutral-800: hsl(0, 0%, 22%);
$color-neutral-900: hsl(0, 0%, 9%);

/*==============================
	SIZES
==============================*/

$max-width: 1422px;
$max-width-wider: 1760px;

/* COMPONENT VARIABLES */

/*==============================
	FORMS
==============================*/

$form-active-color: hsl(218, 76%, 67%);
$form-active-text: hsl(0, 0%, 100%);

$form-item-standard-size: 52px;
$form-item-xl-size: 64px;
$form-item-border-color: $color-neutral-400;
$form-item-border-width: 1px;
$form-item-border-radius: 4px;
$form-item-bg: hsl(0, 0%, 100%);
$form-item-fg: $color-neutral-900;
$form-disabled-item-border-color: $color-neutral-300;
$form-disabled-item-bg: $color-neutral-200;
$form-disabled-item-fg: lighten($form-item-fg, 10%);
$form-item-focus-shadow: rgba(0, 0, 0, 0.12);
$form-icon-color: $color-neutral-400;

/*==============================
	BUTTONS
==============================*/

$button-radius: 11px;
$button-bg: $color-secondary;
$button-fg: #fff;
$button-primary-bg: $color-brand;
$button-primary-fg: #fff;
